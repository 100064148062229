export const getPosts = {
  found: 2,
  posts: [
    {
      ID: 1,
      title: "This is the first post",
      content: "<p>Sample post content</p>"
    },
    {
      ID: 2,
      title: "This is the second post",
      content: "<p>Sample post content</p>"
    }
  ]
};
